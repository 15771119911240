.react-date-picker__wrapper {
  border: none !important;
  font-family: "Open Sans", sans-serif !important;
  color: #2f3a60 !important;
}

.react-date-picker__inputGroup {
  color: #2f3a60 !important;
}

.react-calendar {
  font-family: "Open Sans", sans-serif !important;
  color: #2f3a60 !important;
  border-radius: 5px;
  margin-top: 1rem;
}

.custom-date {
  font-family: "Open Sans", sans-serif !important;
  color: #2f3a60 !important;
}

.react-date-picker {
  width: 98% !important;
  margin: 0 0.5rem;
}

.react-date-picker__inputGroup__input {
  color: #2f3a60 !important;
}
