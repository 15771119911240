@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
:root {
  --thumbBG: #8f8e8e;
}

* {
  margin: 0;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* ::-webkit-scrollbar {
  width: 0; 
  background: transparent;
} */

::-webkit-scrollbar {
  margin-left: 5px;
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  margin-left: 5px;
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #8f8e8e;
  background-color: var(--thumbBG);
  border-radius: 6px;
}

/* Optional: show position indicator in red */
/* ::-webkit-scrollbar-thumb {

} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.loading::before {
  -webkit-animation: dots 1.5s linear infinite;
          animation: dots 1.5s linear infinite;
  content: "";
}

@-webkit-keyframes dots {
  0% {
    content: "";
  }
  20% {
    content: "";
  }
  40% {
    content: ".";
  }
  60% {
    content: "..";
  }
  80% {
    content: "...";
  }
  90% {
    content: "";
  }
  100% {
    content: "";
  }
}

@keyframes dots {
  0% {
    content: "";
  }
  20% {
    content: "";
  }
  40% {
    content: ".";
  }
  60% {
    content: "..";
  }
  80% {
    content: "...";
  }
  90% {
    content: "";
  }
  100% {
    content: "";
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.css-18e2ggt-control:hover {
  border-color: unset !important;
}

.selected {
}
::-webkit-file-upload-button {
  border: 1px solid #2f3a60;
  border-radius: 5px;
  color: #2f3a60;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  cursor: pointer;
}

.primaryLogin {
  border-radius: 100px !important;
  font-size: 18px !important;
  font-weight: bold !important;
  padding: 0.3rem 2rem !important;
  font-family: "Open Sans", sans-serif !important;
  background-color: #fff !important;
  color: #2f3a60 !important;
  text-transform: inherit !important;
}

.secondaryLogin {
  font-size: 16px !important;
  padding: 0.5rem 2rem !important;
  font-family: "Open Sans", sans-serif !important;
  background-color: #2f3a60 !important;
  color: #fff !important;
  text-transform: inherit !important;
  border-radius: 100px !important;
}

.primaryTab {
  display: flex !important;
  justify-content: center !important;
  border-radius: 5px !important;
  font-family: "Open Sans", sans-serif !important;
  font-size: 14px !important;
  justify-self: center !important;
  font-weight: 600 !important;
  box-shadow: none !important;
  padding: 0.4rem 2rem !important;
  background-color: #23c99e !important;
  color: #394673 !important;
  text-transform: inherit !important;
}

.disabledTab {
  display: flex !important;
  justify-content: center !important;
  border-radius: 5px !important;
  font-family: "Open Sans", sans-serif !important;
  font-size: 14px !important;
  justify-self: center !important;
  font-weight: 600 !important;
  box-shadow: none !important;
  padding: 0.4rem 2rem !important;
  background-color: #b6b6b64b !important;
  color: #545c7773 !important;
  text-transform: inherit !important;
}

.warningTab:hover {
  -webkit-filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.096)) !important;
          filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.096)) !important;
  -webkit-transform: scale(1.02) !important;
          transform: scale(1.02) !important;
}

.warningTab {
  display: flex !important;
  justify-content: center !important;
  border-radius: 5px !important;
  font-family: "Open Sans", sans-serif !important;
  font-size: 14px !important;
  justify-self: center !important;
  font-weight: 600 !important;
  box-shadow: none !important;
  padding: 0.4rem 2rem !important;
  background: rgba(255, 212, 113, 0.66) !important;
  color: #394673 !important;
  text-transform: inherit !important;
  transition: all 0.1s !important;
}

.dangerTab:hover {
  -webkit-filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.25)) !important;
          filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.25)) !important;
  -webkit-transform: scale(1.02) !important;
          transform: scale(1.02) !important;
}

.dangerTab {
  display: flex !important;
  justify-content: center !important;
  border-radius: 5px !important;
  font-family: "Open Sans", sans-serif !important;
  font-size: 14px !important;
  justify-self: center !important;
  font-weight: 600 !important;
  box-shadow: none !important;
  padding: 0.4rem 2rem !important;
  background: #ff613f !important;
  color: #ffffff !important;
  text-transform: inherit !important;
  transition: all 0.1s !important;
}

.successTab {
  display: flex !important;
  justify-content: center !important;
  border-radius: 5px !important;
  font-family: "Open Sans", sans-serif !important;
  font-size: 14px !important;
  justify-self: center !important;
  font-weight: 600 !important;
  box-shadow: none !important;
  padding: 0.4rem 2rem !important;
  background-color: #23c99e !important;
  color: #394673 !important;
  text-transform: inherit !important;
  transition: all 0.1s !important;
}

.successTab:hover {
  -webkit-filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.25)) !important;
          filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.25)) !important;
  -webkit-transform: scale(1.02) !important;
          transform: scale(1.02) !important;
}

.primaryTab:hover {
  background-color: #25cea1 !important;
}

.regular {
  border-radius: 5px !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  font-family: "Open Sans", sans-serif !important;
  padding: 0.3rem 2rem !important;
  background-color: #2f3a60 !important;
  font-family: "Open Sans" !important;
  color: #ffffff !important;
  text-transform: inherit !important;
}

.wide {
  border-radius: 5px !important;
  font-weight: 500 !important;
  font-family: "Open Sans", sans-serif !important;
  font-size: 16px !important;
  padding: 0.3rem 2rem !important;
  background-color: #2f3a60 !important;
  color: #ffffff !important;
  text-transform: inherit !important;
  width: 100% !important;
}

.outlined {
  border-radius: 5px !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  padding: 0.3rem 2rem !important;
  background-color: transparent !important;
  font-family: "Open Sans", sans-serif !important;
  border: 1px solid #2f3a60;
  color: #2f3a60 !important;
  text-transform: inherit !important;
}

@media screen and (max-width: 1068px) {
  .primaryLogin {
    font-size: 14px !important;
  }
  .regular {
    font-size: 14px !important;
  }
  .primaryTab {
    font-size: 14px !important;
  }
}

.react-date-picker__wrapper {
  border: none !important;
  font-family: "Open Sans", sans-serif !important;
  color: #2f3a60 !important;
}

.react-date-picker__inputGroup {
  color: #2f3a60 !important;
}

.react-calendar {
  font-family: "Open Sans", sans-serif !important;
  color: #2f3a60 !important;
  border-radius: 5px;
  margin-top: 1rem;
}

.custom-date {
  font-family: "Open Sans", sans-serif !important;
  color: #2f3a60 !important;
}

.react-date-picker {
  width: 98% !important;
  margin: 0 0.5rem;
}

.react-date-picker__inputGroup__input {
  color: #2f3a60 !important;
}

.viewport {
  width: 100vw;
  height: 100vh;
  position: relative;
  left: 0;
  right: 0;
  margin: auto;
  background: linear-gradient(180deg, #2f3a60 0%, #1d2439 100%);
}

.loaderView {
  position: fixed;
  display: block;
  width: 100vw;
  top: 0;
  left: 0;
  height: 100vh;
}

.loaderView * {
  display: block;
  position: absolute;
  width: 51vw;
  height: 100vh;
  overflow: hidden;
}

.loaderView > *:first-child {
  top: 0;
  left: 0;
  background: #fff;
}
.loaderView > *:last-child {
  bottom: 0;
  right: 0;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  background: #fff;
}

@-webkit-keyframes open {
  from {
    width: 50vw;
  }
  to {
    width: 0;
  }
}

@keyframes open {
  from {
    width: 50vw;
  }
  to {
    width: 0;
  }
}
@-webkit-keyframes close {
  to {
    z-index: -100;
  }
}
@keyframes close {
  to {
    z-index: -100;
  }
}

.loader svg {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: auto;
}
.loader svg:nth-child(1) {
  width: 250px;
  height: 250px;
  top: 50px;
  left: 50px;
  -webkit-animation: hide 20s forwards;
          animation: hide 20s forwards;
}
.loader .logo {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 100px;
  top: 0;
  left: 0;
  opacity: 0;
}
.loader .flash {
  -webkit-animation: show 8s infinite linear;
          animation: show 8s infinite linear;
}

@-webkit-keyframes show {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes hide {
  to {
    opacity: 0;
  }
}
@keyframes hide {
  to {
    opacity: 0;
  }
}

