@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --thumbBG: #8f8e8e;
}

* {
  margin: 0;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* ::-webkit-scrollbar {
  width: 0; 
  background: transparent;
} */

::-webkit-scrollbar {
  margin-left: 5px;
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  margin-left: 5px;
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
}

/* Optional: show position indicator in red */
/* ::-webkit-scrollbar-thumb {

} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.loading::before {
  animation: dots 1.5s linear infinite;
  content: "";
}

@keyframes dots {
  0% {
    content: "";
  }
  20% {
    content: "";
  }
  40% {
    content: ".";
  }
  60% {
    content: "..";
  }
  80% {
    content: "...";
  }
  90% {
    content: "";
  }
  100% {
    content: "";
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.css-18e2ggt-control:hover {
  border-color: unset !important;
}

.selected {
}
::-webkit-file-upload-button {
  border: 1px solid #2f3a60;
  border-radius: 5px;
  color: #2f3a60;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  cursor: pointer;
}
