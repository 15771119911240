.viewport {
  width: 100vw;
  height: 100vh;
  position: relative;
  left: 0;
  right: 0;
  margin: auto;
  background: linear-gradient(180deg, #2f3a60 0%, #1d2439 100%);
}

.loaderView {
  position: fixed;
  display: block;
  width: 100vw;
  top: 0;
  left: 0;
  height: 100vh;
}

.loaderView * {
  display: block;
  position: absolute;
  width: 51vw;
  height: 100vh;
  overflow: hidden;
}

.loaderView > *:first-child {
  top: 0;
  left: 0;
  background: #fff;
}
.loaderView > *:last-child {
  bottom: 0;
  right: 0;
  transform: rotate(180deg);
  background: #fff;
}

@keyframes open {
  from {
    width: 50vw;
  }
  to {
    width: 0;
  }
}
@keyframes close {
  to {
    z-index: -100;
  }
}

.loader svg {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: auto;
}
.loader svg:nth-child(1) {
  width: 250px;
  height: 250px;
  top: 50px;
  left: 50px;
  animation: hide 20s forwards;
}
.loader .logo {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 100px;
  top: 0;
  left: 0;
  opacity: 0;
}
.loader .flash {
  animation: show 8s infinite linear;
}

@keyframes show {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes hide {
  to {
    opacity: 0;
  }
}
