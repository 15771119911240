.primaryLogin {
  border-radius: 100px !important;
  font-size: 18px !important;
  font-weight: bold !important;
  padding: 0.3rem 2rem !important;
  font-family: "Open Sans", sans-serif !important;
  background-color: #fff !important;
  color: #2f3a60 !important;
  text-transform: inherit !important;
}

.secondaryLogin {
  font-size: 16px !important;
  padding: 0.5rem 2rem !important;
  font-family: "Open Sans", sans-serif !important;
  background-color: #2f3a60 !important;
  color: #fff !important;
  text-transform: inherit !important;
  border-radius: 100px !important;
}

.primaryTab {
  display: flex !important;
  justify-content: center !important;
  border-radius: 5px !important;
  font-family: "Open Sans", sans-serif !important;
  font-size: 14px !important;
  justify-self: center !important;
  font-weight: 600 !important;
  box-shadow: none !important;
  padding: 0.4rem 2rem !important;
  background-color: #23c99e !important;
  color: #394673 !important;
  text-transform: inherit !important;
}

.disabledTab {
  display: flex !important;
  justify-content: center !important;
  border-radius: 5px !important;
  font-family: "Open Sans", sans-serif !important;
  font-size: 14px !important;
  justify-self: center !important;
  font-weight: 600 !important;
  box-shadow: none !important;
  padding: 0.4rem 2rem !important;
  background-color: #b6b6b64b !important;
  color: #545c7773 !important;
  text-transform: inherit !important;
}

.warningTab:hover {
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.096)) !important;
  transform: scale(1.02) !important;
}

.warningTab {
  display: flex !important;
  justify-content: center !important;
  border-radius: 5px !important;
  font-family: "Open Sans", sans-serif !important;
  font-size: 14px !important;
  justify-self: center !important;
  font-weight: 600 !important;
  box-shadow: none !important;
  padding: 0.4rem 2rem !important;
  background: rgba(255, 212, 113, 0.66) !important;
  color: #394673 !important;
  text-transform: inherit !important;
  transition: all 0.1s !important;
}

.dangerTab:hover {
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.25)) !important;
  transform: scale(1.02) !important;
}

.dangerTab {
  display: flex !important;
  justify-content: center !important;
  border-radius: 5px !important;
  font-family: "Open Sans", sans-serif !important;
  font-size: 14px !important;
  justify-self: center !important;
  font-weight: 600 !important;
  box-shadow: none !important;
  padding: 0.4rem 2rem !important;
  background: #ff613f !important;
  color: #ffffff !important;
  text-transform: inherit !important;
  transition: all 0.1s !important;
}

.successTab {
  display: flex !important;
  justify-content: center !important;
  border-radius: 5px !important;
  font-family: "Open Sans", sans-serif !important;
  font-size: 14px !important;
  justify-self: center !important;
  font-weight: 600 !important;
  box-shadow: none !important;
  padding: 0.4rem 2rem !important;
  background-color: #23c99e !important;
  color: #394673 !important;
  text-transform: inherit !important;
  transition: all 0.1s !important;
}

.successTab:hover {
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.25)) !important;
  transform: scale(1.02) !important;
}

.primaryTab:hover {
  background-color: #25cea1 !important;
}

.regular {
  border-radius: 5px !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  font-family: "Open Sans", sans-serif !important;
  padding: 0.3rem 2rem !important;
  background-color: #2f3a60 !important;
  font-family: "Open Sans" !important;
  color: #ffffff !important;
  text-transform: inherit !important;
}

.wide {
  border-radius: 5px !important;
  font-weight: 500 !important;
  font-family: "Open Sans", sans-serif !important;
  font-size: 16px !important;
  padding: 0.3rem 2rem !important;
  background-color: #2f3a60 !important;
  color: #ffffff !important;
  text-transform: inherit !important;
  width: 100% !important;
}

.outlined {
  border-radius: 5px !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  padding: 0.3rem 2rem !important;
  background-color: transparent !important;
  font-family: "Open Sans", sans-serif !important;
  border: 1px solid #2f3a60;
  color: #2f3a60 !important;
  text-transform: inherit !important;
}

@media screen and (max-width: 1068px) {
  .primaryLogin {
    font-size: 14px !important;
  }
  .regular {
    font-size: 14px !important;
  }
  .primaryTab {
    font-size: 14px !important;
  }
}
